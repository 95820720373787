<template>
  <HelpComponent>
    <b-row class="mb-3">
      <b-col>
        Cet &eacute;cran vous donne acc&egrave;s aux r&eacute;sultats d'analyse
        des &eacute;chantillons pr&eacute;lev&eacute;s sur votre stock en ferme.
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col>
        Le r&eacute;sultat de l'analyse ainsi que le document
        r&eacute;capitulatif sont tous deux t&eacute;l&eacute;chargeables le
        lendemain de leur saisie par votre coop&eacute;rative.
      </b-col>
    </b-row>
    <DangerBloc>
      Comme sp&eacute;cifi&eacute; dans votre r&egrave;glement de campagne,
      seule l'analyse effectu&eacute;e sur l'&eacute;chantillon
      pr&eacute;lev&eacute;
      <strong>au moment de l'apport</strong> est prise en compte pour la
      facturation. <br />
      Les analyses de cet &eacute;cran vous sont fournies &agrave; titre
      indicatif et ne pr&eacute;sument ni de la repr&eacute;sentativit&eacute;
      de l'&eacute;chantillon, ni de la qualit&eacute; du stockage en ferme.
    </DangerBloc>
  </HelpComponent>
</template>

<script>
import HelpComponent from "./HelpComponent.vue";
import DangerBloc from "../blocs/DangerBloc.vue";
export default {
  name: "HelpAnalyseMoissonComponent",
  components: { HelpComponent, DangerBloc },
};
</script>
