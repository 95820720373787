<template>
  <b-modal ref="modal" scrollable @close="cancel">
    <template #modal-title>{{ title }}</template>
    <slot>
      <b-row v-for="(message, index) in messages" :key="index" class="mb-3">
        <b-col>{{ message }}</b-col>
      </b-row>
    </slot>
    <template #modal-footer>
      <b-button block pill variant="primary" @click="cancel">
        {{ closeLabel }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "InformationDialog",
  data: () => ({
    title: "Information",
    messages: [],
    closeLabel: "Fermer",
    resolvePromise: null,
    rejectPromise: null,
  }),
  methods: {
    show(options = {}) {
      this.title = options?.title ?? "Information";
      this.messages = options?.messages ?? [];
      this.closeLabel = options?.closeLabel ?? "Fermer";
      this.$refs["modal"].show();
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    cancel() {
      this.$refs["modal"].hide();
      this.resolvePromise(false);
    },
  },
};
</script>
