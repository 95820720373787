<template>
  <b-button pill variant="primary" @click="openModal">
    <b-icon-info-circle></b-icon-info-circle>
    <span class="d-none d-md-inline"> Information</span>
    <InformationDialog ref="informationDialog">
      <slot></slot>
    </InformationDialog>
  </b-button>
</template>

<script>
import InformationDialog from "../dialogs/InformationDialog.vue";
export default {
  name: "HelpComponent",
  props: ["title", "messages", "closeLabel"],
  components: { InformationDialog },
  methods: {
    openModal() {
      this.$refs.informationDialog.show({
        title: this.title,
        messages: this.messages,
        closeLabel: this.closeLabel,
      });
    },
  },
};
</script>
